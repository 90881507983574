<template>
  <div class="schedule-block">
    <menu-breadcrumb>
      <el-breadcrumb-item>檔期管理</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          檔期列表 <span class="unit">數量：{{ total }}</span>
        </p>

        <el-button
          v-if="role !== 'work-manager'"
          type="success"
          @click="$router.push({ name: 'CreateSchedule' })"
          >新增檔期
        </el-button>
      </table-title>

      <el-table :data="schedule_data">
        <el-table-column label="啟用" prop="active" width="80" sortable>
          <template v-slot="{ row }">
            <el-checkbox
              :value="row.active"
              @change="handleActive(row.schedule_id)"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          label="中文名稱"
          prop="name_zh"
          sortable
        ></el-table-column>
        <el-table-column
          label="英文名稱"
          prop="name_en"
          sortable
        ></el-table-column>
        <el-table-column
          label="開展日期"
          prop="start_date"
          width="200"
          sortable
        >
          <template v-slot="{ row }">
            {{ $formatTime(row.start_date) }}
          </template>
        </el-table-column>
        <el-table-column
          label="閉展日期"
          prop="end_date"
          width="200px"
          sortable
        >
          <template v-slot="{ row }">
            {{ $formatTime(row.end_date) }}
          </template>
        </el-table-column>
        <el-table-column label="編輯" fixed="right" width="260">
          <template v-slot:header="{}">
            <el-input
              v-model="setup.search"
              size="mini"
              placeholder="請輸入關鍵字"
              @keyup.enter.native="handleSearch"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-input>
          </template>

          <template v-slot="{ row }">
            <el-button
              type="primary"
              icon="el-icon-setting"
              size="mini"
              @click="handleRouter('DetailSchedule', row.schedule_id)"
              >內容</el-button
            >
            <el-button
              type="warning"
              size="mini"
              icon="el-icon-edit"
              @click="handleRouter('EditSchedule', row.schedule_id)"
              >編輯</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(row.schedule_id)"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import {
  getSchedulesPagination,
  updateSchedule,
  removeSchedule,
} from "@/api/schedule";

export default {
  name: "Schedule",
  data() {
    return {
      schedule_data: [],
      total: 0,
      setup: {
        page: 0,
        limit: 25,
        search: "",
      },
    };
  },
  computed: {
    role() {
      return this.$store.getters.role;
    },
  },
  created() {
    const { p, s } = this.$route.query;
    if (p) {
      this.setup.page = +p;
    }
    if (s) {
      this.setup.search = decodeURIComponent(s);
    }
    this.handleGetSchedule();
  },
  methods: {
    async handleGetSchedule() {
      const { results, total } = await getSchedulesPagination(this.setup);

      this.schedule_data = results;
      this.total = total;
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetSchedule();
    },
    async handleSearch() {
      this.setup.page = 0;

      let query = {
        p: this.setup.page,
        s: encodeURIComponent(this.setup.search),
      };

      if (decodeURIComponent(query.s).length === 0) delete query.s;

      await this.$router.push({ query });

      await this.handleGetSchedule();
    },
    async handleActive(id) {
      const schedule = this.schedule_data.find(
        ({ schedule_id }) => schedule_id === id
      );

      if (!schedule) return;

      schedule.active = !schedule.active;
      const { schedule_id, ...data } = schedule;

      await updateSchedule(schedule_id, data);

      this.$message({
        type: "success",
        message: "編輯成功",
      });
    },
    handleRouter(name, id) {
      this.$router.push({ name, params: { id } });
    },
    handleDelete(scheduleId) {
      this.$confirm("是否刪除該檔期", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await removeSchedule(+scheduleId);
          await this.handleGetSchedule();

          this.$message({
            type: "success",
            message: "刪除成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
