var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"schedule-block"},[_c('menu-breadcrumb',[_c('el-breadcrumb-item',[_vm._v("檔期管理")])],1),_c('div',{staticClass:"container"},[_c('table-title',[_c('p',[_vm._v(" 檔期列表 "),_c('span',{staticClass:"unit"},[_vm._v("數量："+_vm._s(_vm.total))])]),(_vm.role !== 'work-manager')?_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'CreateSchedule' })}}},[_vm._v("新增檔期 ")]):_vm._e()],1),_c('el-table',{attrs:{"data":_vm.schedule_data}},[_c('el-table-column',{attrs:{"label":"啟用","prop":"active","width":"80","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"value":row.active},on:{"change":function($event){return _vm.handleActive(row.schedule_id)}}})]}}])}),_c('el-table-column',{attrs:{"label":"中文名稱","prop":"name_zh","sortable":""}}),_c('el-table-column',{attrs:{"label":"英文名稱","prop":"name_en","sortable":""}}),_c('el-table-column',{attrs:{"label":"開展日期","prop":"start_date","width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$formatTime(row.start_date))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"閉展日期","prop":"end_date","width":"200px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$formatTime(row.end_date))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"編輯","fixed":"right","width":"260"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('el-input',{attrs:{"size":"mini","placeholder":"請輸入關鍵字"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.setup.search),callback:function ($$v) {_vm.$set(_vm.setup, "search", $$v)},expression:"setup.search"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.handleSearch},slot:"append"})],1)]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","icon":"el-icon-setting","size":"mini"},on:{"click":function($event){return _vm.handleRouter('DetailSchedule', row.schedule_id)}}},[_vm._v("內容")]),_c('el-button',{attrs:{"type":"warning","size":"mini","icon":"el-icon-edit"},on:{"click":function($event){return _vm.handleRouter('EditSchedule', row.schedule_id)}}},[_vm._v("編輯")]),_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDelete(row.schedule_id)}}},[_vm._v("刪除")])]}}])})],1),_c('table-pagination',{attrs:{"total":_vm.total,"limit":_vm.setup.limit,"page":_vm.setup.page},on:{"change-page":_vm.handleChangePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }